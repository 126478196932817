import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { bookingSignal } from '../pages/BookingPage/signal';
import BookingSalonInfo from './SalonInfo';
import StaffReviews from './StaffReviews';
import { ContainerFooter, WrapperFooter } from '../pages/BookingPage/FooterActions';
import SalonContactView from './SalonContactView';
import { useAppDispatch } from 'store/hooks';
import shopActions from 'services/shop/actions';
import shopSelectors from 'services/shop/selectors';
import { formatCurrency } from 'utils/formatCurrency';
import aptActions from 'features/Booking/services/actions';

const SalonReviewsPage = () => {
  const { shop_id = '' } = useParams();
  const [query] = useSearchParams();
  const customer_id = query.get('customer_id');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(shopActions.galleries.fetch());
  }, []);

  const onBookWithStaff = (staffId: string) => {
    bookingSignal.setDefaultStaff(staffId);
    if (customer_id) dispatch(aptActions.initCustomerId(customer_id));
    navigate(`/v2/${shop_id}`);
  };
  const onBookNow = () => {
    bookingSignal.setDefaultStaff(null);
    if (customer_id) dispatch(aptActions.initCustomerId(customer_id));
    navigate(`/v2/${shop_id}`);
  };

  return (
    <Container>
      <BookingSalonInfo />
      <SalonContactView />
      {/* <ReviewComments /> */}
      <StaffReviews onBookWithStaff={onBookWithStaff} />
      <Footer onBookNow={onBookNow} />
    </Container>
  );
};

export default SalonReviewsPage;
const Footer = ({ onBookNow }: { onBookNow: () => void }) => {
  const promotion = shopSelectors.promotion();
  return (
    <WrapperFooter>
      <ContainerFooter>
        <button onClick={onBookNow} type='button' className='btn btn-submit'><span>{promotion ? `Book Now To Get ${promotion.promotionType === 'PERCENT' ? `${promotion.promotionValue}%` : formatCurrency(promotion.promotionValue)} OFF` : 'Book Now'}</span></button>
      </ContainerFooter>
    </WrapperFooter>
  );
};

const Container = styled.div`
background: #F6F7FC;
.BookingSalonInfo {
  padding: 0;
  background: #F6F7FC;
  .banner-container .banner-img {
    height: 147px;
  }
  .detail-info-container {
    padding: 0 16px;
    padding-bottom: 12px;
    .title {
      font-size: 24px;
    }
  }
}
`;
